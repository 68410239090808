import { Switch, Route } from 'react-router-dom';
import React from 'react';

const App = () => {
  return (
    <Switch>
      <Route
        component={() => {
          window.location.href = 'https://portal.gochargeport.com';
          return null;
        }}
      />
    </Switch>
  );
};

export default App;
